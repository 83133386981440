// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dagong-js": () => import("./../src/pages/dagong.js" /* webpackChunkName: "component---src-pages-dagong-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lianliankan-js": () => import("./../src/pages/lianliankan.js" /* webpackChunkName: "component---src-pages-lianliankan-js" */),
  "component---src-pages-shuifandui-js": () => import("./../src/pages/shuifandui.js" /* webpackChunkName: "component---src-pages-shuifandui-js" */),
  "component---src-pages-wangjingze-js": () => import("./../src/pages/wangjingze.js" /* webpackChunkName: "component---src-pages-wangjingze-js" */),
  "component---src-pages-weisuoyuwei-js": () => import("./../src/pages/weisuoyuwei.js" /* webpackChunkName: "component---src-pages-weisuoyuwei-js" */),
  "component---src-pages-woshuode-js": () => import("./../src/pages/woshuode.js" /* webpackChunkName: "component---src-pages-woshuode-js" */)
}

